<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 商品分类 </template>
      <template #input>
        <a-button
          class="all_boder_btn"
          @click="
            state = 1;
            id = null;
            pid = 0;
            (visible = true), (status = false), (name = '');
          "
          >新建分类</a-button
        >
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :expandIcon="expandIcon"
      :columns="columns"
      :data-source="data"
      :defaultExpandAllRows="true"
      :pagination="false"
      :rowKey="(item) => item.id"
      :loading="loading"
      childrenColumnName="children"
    >
      <template slot="show" slot-scope="item, row">
        <span class="blueText" v-if="!(row.first == 1 && row.last == 1)">
          <span
            v-if="row.first != 1"
            @click="
              id = row.id;
              pid = row.pid;
              moveData(1);
            "
            >前移</span
          >
          <span v-if="row.first == 0 && row.last == 0"> | </span>
          <span
            v-if="row.last != 1"
            @click="
              id = row.id;
              pid = row.pid;
              moveData(2);
            "
            >后移</span
          >
        </span>
        <span v-else>-</span>
      </template>

      <template slot="catalogue" slot-scope="item, row">
        <span class="blueText">
          <template v-if="row.pid == 0">
            <a
              @click="
                state = 1;
                pid = row.id;
                pidName = row.name;
                id = row.id;
                (visible = true), (status = true), (name = '');
              "
              >新建子分类</a
            >
            <span v-if="!!row.noOperation"> | </span>
          </template>
          <a
            v-if="!!row.noOperation"
            @click="
              state = 2;
              id = row.id;
              pid = row.pid;
              pidName = row.pname;
              name = row.name;
              (visible = true), (status = row.pid ? true : false);
            "
            >编辑</a
          >
          <span v-if="!!row.noOperation"> | </span>
          <a v-if="!!row.noOperation" @click="removeData(row.id)">删除</a>
        </span>
      </template>
    </a-table>

    <!-- 新建/编辑 -->
    <a-modal
      v-model="visible"
      :title="(state == 1 ? '新建' : '编辑') + (pid == 0 ? '' : '子') + '分类'"
      @ok="state == 1 ? addData() : setData()"
      :confirm-loading="PreventLoad"
      @cancel="name = ''"
      okText="确认"
    >
      <!-- 根分类 -->
      <template v-if="pid == 0">
        <a-row class="rows">
          <a-col span="5" class="left">分类名称</a-col>
          <a-col span="19" class="right">
            <a-input placeholder="请输入分类名称" v-model="name" />
          </a-col>
        </a-row>
      </template>
      <!-- 子分类 -->
      <template v-else>
        <a-row class="rows">
          <a-col span="5" class="left">当前分类</a-col>
          <a-col span="19" class="right">
            <div style="line-height: 32px">{{ pidName }}</div>
          </a-col>
        </a-row>
        <a-row class="rows">
          <a-col span="5" class="left">子分类名称</a-col>
          <a-col span="19" class="right">
            <a-input placeholder="请输入子分类名称" v-model="name" />
          </a-col>
        </a-row>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "分类名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "终端展示排序",
    align: "left",
    dataIndex: "show",
    key: "show",
    scopedSlots: { customRender: "show" },
  },
  {
    title: "目录操作",
    // align: "center",
    dataIndex: "catalogue",
    width: "200px",
    key: "catalogue",
    scopedSlots: { customRender: "catalogue" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      id: null,
      pid: 0,
      pidName: "",
      status: false, // 是否编辑子分类
      state: 1, // 1新建，2编辑
      visible: false,
      data: [],
      columns,
      name: "",
      loading: false,
      // expandedRowKeys: [],
      PreventLoad: false,
    };
  }, // 事件处理器
  methods: {
    expandIcon(props) {
      if (props.record.children && props.record.children.length > 0) {
        if (props.expanded) {
          //有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-down" />{" "}
            </a>
          );
        } else {
          //有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-right" />
            </a>
          );
        }
      } else {
        return <span style={{ marginRight: 8 }}></span>;
      }
    },

    // 查询列表数据
    getList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/product-category/manage/list",
        params: {
          // name: 1,
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.data = res.data;
          }
          setTimeout(() => {
            this.loading = false;
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },

    // 新建分类
    addData() {
      if (this.PreventLoad) {
        return;
      }
      if (!this.name) {
        return this.$message.error("请输入分类名称");
      }
      if (this.status) {
        if (this.name.length > 8) {
          return this.$message.error("分类名称过长");
        }
      } else {
        if (this.name.length > 4) {
          return this.$message.error("分类名称过长");
        }
      }
      this.PreventLoad = true;
      this.$ajax({
        url: "/hxclass-management/product-category/manage/save",
        method: "post",
        params: {
          categoryId: this.id,
          name: this.name,
          pid: this.pid,
        },
      }).then((res) => {
      this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.getList();
          this.name = "";
          this.visible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 修改分类
    setData() {
      if (!this.name) {
        return this.$message.error("请输入分类名称");
      }
      if (this.status) {
        if (this.name.length > 8) {
          return this.$message.error("分类名称过长");
        }
      } else {
        if (this.name.length > 4) {
          return this.$message.error("分类名称过长");
        }
      }
      this.$ajax({
        url: "/hxclass-management/product-category/manage/update",
        method: "put",
        params: {
          categoryId: this.id,
          pid: this.pid,
          name: this.name,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getList();
          this.visible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 删除分类
    removeData(e) {
      this.$confirm({
        title: "确定删除该标签吗?",
        centered: true,
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/product-category/manage/delete/" + e,
            method: "delete",
            params: {
              categoryId: e,
            },
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },

    // 移动
    moveData(e) {
      if (this.loading) {
        return false;
      } else {
        this.loading = true;
      }
      this.$ajax({
        url: "/hxclass-management/product-category/manage/update/sort",
        method: "put",
        params: {
          categoryId: this.id,
          move: e,
          type: 1,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getList();
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getList();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
  .btn {
    margin: 0 24px 14px 0;
  }
  // a {
  //   color: #000000;
  // }
}
.rows {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    // &::before{
    //   content: '*';
    //   color: red;
    //   margin-right: 2px;
    // }
    &::after {
      content: "：";
    }
  }
  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input {
      flex: 1;
    }
    a {
      margin-left: 8px;
    }
  }
}
</style>
